import React from 'react';
import {
	Box,
	DeezerLogo,
	Link,
	Stack,
	TopBar,
	TopBarActions,
	TopBarList,
	TopBarLogo,
} from '@tempo/core';
import {NavLink} from '@app/components/Navlink';
import {useLocation} from '@app/hooks/useLocation';
import dynamic from 'next/dynamic';

const WrappedAvatar = dynamic(() => import('./WrappedAvatar'));

type TopbarConfig = {
	links: {url: string; name: string; tracking: string}[];
};
const useTopbarConfig = (): readonly [TopbarConfig] => {
	return [{links: []}];
};

export type TopbarProps = {
	clientLogo?: React.ReactNode;
};

export const Topbar: React.FC<TopbarProps> = ({clientLogo = null}) => {
	const {getDeezerUrl} = useLocation();
	const [config] = useTopbarConfig();
	const bgProps =
		clientLogo === null ? {} : {bg: 'white', _dark: {bg: 'black'}};
	return (
		<TopBar px={{base: 4, md: 24}} {...bgProps} py={{base: 3, md: 5}}>
			<TopBarLogo>
				<Stack
					as={Link}
					href={!clientLogo ? getDeezerUrl('/') : undefined}
					pointerEvents={!clientLogo ? 'auto' : 'none'}
					alignItems="center"
					direction="row"
					spacing="spacing.l"
				>
					<DeezerLogo
						height={{base: '22px', md: '28px'}}
						data-testid="deezer-logo"
					/>
					{clientLogo && (
						<>
							<Box
								height={{base: '22px', md: '28px'}}
								width="1px"
								bg="border.neutral.primary.default"
							/>
							{clientLogo}
						</>
					)}
				</Stack>
			</TopBarLogo>
			{config.links.map(({url, name}) => (
				<NavLink key={name} fontWeight="semibold" href={url}>
					{name}
				</NavLink>
			))}
			<TopBarList alignItems="center"></TopBarList>
			<TopBarActions>
				<WrappedAvatar />
			</TopBarActions>
		</TopBar>
	);
};
