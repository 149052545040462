import {usePrevious} from '@deezer/react-utils';
import {useEffect} from 'react';
import {useUserIdentifyProfile, getCDP} from '.';
import {cdpStrategy} from '../logCenter';

/**
 * @deprecated - For init use only, prefer using the one from @app/modules/tracking instead
 */
export const useInitCDP = () => {
	const profile = useUserIdentifyProfile();
	const previousProfile = usePrevious(profile);

	useEffect(() => {
		if (!profile) return;

		// First load
		if (!previousProfile) {
			getCDP().init();
			getCDP().identify(profile);
			cdpStrategy.openGate();
		}
	}, [profile, previousProfile]);
	return Boolean(profile);
};
