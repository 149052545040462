import {useCallback} from 'react';
import {
	MIN_LEGAL_AGE,
	PASSWORD_MAX_LENGTH,
	USERNAME_MAX_LENGTH,
	USERNAME_MIN_LENGTH,
} from '@app/modules/yup/constants';
import {type TranslationParams, useTranslation} from './useTranslation';

interface Payload {
	required_info: string[];
}

type RandomObj = {[key: string]: unknown};
export type LegacyApiError<
	N extends string = string,
	M extends string = string,
	R extends RandomObj = RandomObj,
	P extends RandomObj = RandomObj,
> = {
	message: N;
	name: M;
	results?: R;
	payload?: P;
};

export const isLegacyApiError = (obj: any): obj is LegacyApiError =>
	obj.name && obj.message;

export const isMissingInfoError = (obj: any): obj is LegacyApiError =>
	obj.message === 'missing_info';

export function isVerifyEmailError(obj: any): obj is LegacyApiError {
	return (
		isLegacyApiError(obj) &&
		obj.name === 'USER_AUTH_ERROR' &&
		obj.message === 'email_verification_required'
	);
}

const isConsentTurkeyError = (obj: any): obj is LegacyApiError => {
	if (!isMissingInfoError(obj)) return false;

	const payload = obj.payload as unknown;

	if (
		typeof payload === 'object' &&
		payload !== null &&
		'required_info' in payload
	) {
		const requiredInfo = (payload as Payload).required_info;
		return (
			Array.isArray(requiredInfo) && requiredInfo[0] === 'consent_transfer_data'
		);
	}

	return false;
};

const SIMPLE_ERROR_MAPPING: Record<string, TranslationParams> = {
	birthday_not_valid: ['error.ageisinvalid'],
	blogname_empty: ['error.fieldmustnotbeempty'],
	blogname_not_valid: ['formal.usernamecannotcontainsemojis'],
	blogname_too_long: [
		'formal.usernametoomanychars',
		{
			sprintf: [USERNAME_MAX_LENGTH],
			count: USERNAME_MAX_LENGTH,
		},
	],
	blogname_too_short: [
		'formal.usernametoomanychars',
		{
			sprintf: [USERNAME_MIN_LENGTH],
			count: USERNAME_MIN_LENGTH,
		},
	],
	code_too_long: ['error.codetoolong'],
	code_too_short: ['error.codetooshort'],
	country_not_valid: ['formal.signupunavailableinyourcountry'],
	'Email already exists': ['error.accountalrdyassociatedwithemail'],
	email_empty: ['error.fieldmustnotbeempty'],
	email_not_valid: ['formal.emailformatnotvalid'],
	invalid_code: ['error.invalidcode'],
	invalid_token: ['error.anerroroccurredpleasetryagain'],
	password_empty: ['error.fieldmustnotbeempty'],
	password_with_spaces: ['formal.passwordnbeginendwithspace'],
	password_too_long: [
		'formal.passwordtoomanychars',
		{
			sprintf: [PASSWORD_MAX_LENGTH],
		},
	],
	password_too_short: [
		'formal.passwordnotenoughchars',
		{
			sprintf: [8],
		},
	],
	password_too_weak: ['password.tooweak'],
	recaptcha_empty: ['error.anerroroccurredpleasetryagain'],
	recaptcha_not_valid: ['error.anerroroccurredpleasetryagain'],
	sms_login_limit: ['error.smscodelimit'],
	sms_not_sent: ['error.maxnbercodes'],
};

export const useLegacyApiError = (): readonly [
	(err: LegacyApiError) => string,
] => {
	const t = useTranslation();
	const getTranslatedErrorMsg = useCallback(
		(err: LegacyApiError) => {
			let errorMsg = t('error.anerroroccurredpleasetryagain');
			switch (err.name) {
				case 'COUNTRY_CLOSED_FREEMIUM':
					errorMsg = t('formal.deezerunavailableinyourcountry');
					break;
				case 'USER_AUTH_ERROR':
					errorMsg = t('formal.incorrectdetailstryagain');
					break;
				case 'PARAMETER_ERROR': {
					if (err.message === 'invalid_msisdn') {
						errorMsg = t('error.invalidphonenumber');
					}
					break;
				}
				case 'DATA_ERROR':
				case 'REQUEST_ERROR': {
					// Management of requests errors messages that don't need context
					const simpleErrorParams = SIMPLE_ERROR_MAPPING[err.message];
					if (simpleErrorParams) {
						errorMsg = t(...simpleErrorParams);
						break;
					}

					if (isConsentTurkeyError(err)) {
						errorMsg = t('error.turkeyconsenterror');
					}

					// Management of request errors that need context
					switch (err.message) {
						case 'min_legal_age': {
							const minAge = (err.payload?.age as number) ?? MIN_LEGAL_AGE;
							errorMsg = t('formal.error_agemustbeXyearsentervaliddate', {
								count: minAge,
								sprintf: [minAge],
							});
							break;
						}

						// email error message
						case 'email_domain_not_valid':
							errorMsg = t('error.domainnnamenotallowed', {
								sprintf: [err.payload?.domain as string],
							});
							break;
					}
					break;
				}
			}
			return errorMsg;
		},
		[t],
	);
	return [getTranslatedErrorMsg];
};
