import {config} from '@app/modules/config';
import {useUserConsent} from '@tempo/cookie';
import {CDP} from '@deezer/logcenter';
import type {TraitEvent} from '@deezer/logcenter/build/types/Sender/CDPSender/types';
import {useUserData} from '@app/hooks/controllers/useUserData';
import {getUniqId} from '../utils';

const buildDefaultCDP = () => {
	return new CDP(config.get('segmentApiKey'));
};

let cdp: CDP;
export const getCDP = (): CDP => {
	if (!cdp) {
		cdp = buildDefaultCDP();
	}
	return cdp;
};

export function useUserIdentifyProfile(): TraitEvent | null {
	const {user} = useUserData();
	const {consentSettings} = useUserConsent();

	if (!user || !consentSettings) return null;
	const profile: TraitEvent = {
		statistics: !!consentSettings.statistics,
		targetedAds: !!consentSettings.targetedAdvertising,
		deezer_unique_id: getUniqId(),
		userId: user?.id?.toString(),
	};
	if (user?.email) {
		profile.email = user?.email;
	}
	return profile;
}
