import {useEffect} from 'react';
import {usePathname} from 'next/navigation';
import type {Trackables} from './types';
import {
	getAppNameFromPathUrl,
	getIsOauthFromPath,
} from '@app/modules/apps/utils';
import {useIsPaymentJourney} from '@app/modules/redirection/hooks';
import {useRouter} from 'next/router';
import {logGTM} from '.';
import {gtmStrategy} from '../logCenter';
import {usePrevious} from '@deezer/react-utils';
import {useUserData} from '@app/hooks/controllers/useUserData';
import {useAutolog} from '@app/hooks/auth/useAutolog';
import {getUniqId} from '../utils';
import {AuthStatus} from '@app/hooks/auth/useAutolog/utils';

const prettyPathToPageNameMapping: Record<string, string> = {
	signup: 'register',
	'': 'home',
};

export function determinePageTrackingContextFromPath(
	path: string,
	isPaid: boolean,
) {
	const payPrefix = isPaid ? 'pay_' : '';
	const appName = getAppNameFromPathUrl(path);
	const isOauthPrefix = getIsOauthFromPath(path) ? 'oauth_' : '';

	const prettyPath =
		path === `/${appName.toLowerCase()}`
			? 'register'
			: path
					.split('/')
					.filter((pathElement) => {
						return (
							pathElement &&
							pathElement !== 'oauth' &&
							pathElement !== appName.toLowerCase()
						);
					})
					.join('/');
	return {
		pagecategory: `${appName.toLowerCase()}_account`,
		pagename: `${payPrefix}${isOauthPrefix}${
			prettyPathToPageNameMapping[prettyPath] ?? prettyPath
		}`,
	} satisfies Partial<Trackables>;
}

const usePageTrackingContext = () => {
	const pathname = usePathname();
	const isPaid = useIsPaymentJourney();
	const {status} = useAutolog();
	const {user} = useUserData();

	// We still have to use old router unfortunately to make sure in pages that the params have been fully evaluated
	const {isReady} = useRouter();
	const userContext = {
		dzr_uniq_id: getUniqId(),
		deezer_user_id: user?.id?.toString() ?? '0',
		country: user?.country ?? 'null',
		offer_id: user?.offerId ? Number(user?.offerId) : 0,
	};
	const pageContext = determinePageTrackingContextFromPath(pathname, isPaid);

	return isReady && status !== AuthStatus.loading
		? ({
				...pageContext,
				...userContext,
				eventcategory: pageContext.pagename,
			} satisfies Partial<Trackables>)
		: null;
};

export function usePageView() {
	const trackingContext = usePageTrackingContext();
	const previous = usePrevious(trackingContext);

	useEffect(() => {
		if (
			!trackingContext ||
			JSON.stringify(trackingContext) === JSON.stringify(previous)
		) {
			return;
		}
		logGTM(
			{
				event: 'pageView',
				...trackingContext,
			},
			true,
		);
		gtmStrategy.openGate();
	}, [trackingContext, previous]);
}
