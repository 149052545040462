import {useSwrApi} from '@app/modules/api/swr-legacy-api';
import {useAppState} from '@app/modules/apps/context';
import {useAutolog} from '@app/hooks/auth/useAutolog';
import {AuthStatus, isMissingInfoError} from '@app/hooks/auth/useAutolog/utils';
import {transformLegacyUser} from '@app/utils/userData';
import {useRouter} from 'next/router';

export function useUserData() {
	const {appName} = useAppState();
	const {status} = useAutolog();
	const {push, pathname} = useRouter();

	const {data, mutate, ...rest} = useSwrApi(
		status === AuthStatus.loading
			? null
			: {
					method: 'deezer.getUserData',
					data: {
						APP_NAME: appName,
					},
				},
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			onErrorRetry(err, _, __, revalidate, {retryCount}) {
				if (retryCount >= 3 || isMissingInfoError(err)) {
					return;
				}
				setTimeout(() => revalidate({retryCount}), retryCount * 1000);
			},
			onError(data) {
				// redirect only for internal account urls
				if (
					isMissingInfoError(data) &&
					!document.referrer &&
					!pathname.includes('/missinginfo')
				) {
					push({pathname: '/missinginfo'});
				}
			},
			transform: (result, error) => {
				if (error) {
					throw error;
				}
				return transformLegacyUser(result);
			},
		},
	);

	return {
		user: data,
		refetchUser: mutate,
		...rest,
	};
}
