import {useAppState} from '@app/modules/apps/context';
import AuthVitalsMetric from '@app/modules/metrics/authVitals';
import type {AuthVitalsLog} from '@app/modules/metrics/authVitals/type';
import {logCenter} from '@app/modules/tracking/logCenter';
import {useCallback, useEffect} from 'react';
import {useUserData} from '../controllers/useUserData';
import {getUniqId} from '@app/modules/tracking/utils';

function safeStringify(obj: any) {
	try {
		return JSON.stringify(obj);
	} catch (error) {
		return 'null';
	}
}

export type ErrorContext = {e: Error; payload?: any} & Omit<
	AuthVitalsLog,
	'app_name' | 'error' | 'device_uniq_id'
>;

export class AuthError extends Error {
	context: ErrorContext;
	constructor(public errorCtx: ErrorContext) {
		super(errorCtx.e.message);
		this.context = errorCtx;
	}

	getContext() {
		return this.context;
	}
}

export function obfuscateUserDataFromError(error: string) {
	return (
		[
			// Email
			[/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g, '***@***.**'],
			// Phone number
			[
				/(?:\+|00)?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g,
				'redacted_phone_number',
			],
		] satisfies [RegExp, string][]
	).reduce((acc, [regex, replacement]) => {
		return acc.replace(regex, replacement);
	}, error);
}

export const useAuthVitalsMetrics = () => {
	const {appName, isOauth} = useAppState();
	const {user} = useUserData();
	const logAuthError = useCallback(
		(errorCtx: ErrorContext) => {
			const {e, error_extra, gateway_method, payload, ...rest} = errorCtx;

			logCenter.log(
				new AuthVitalsMetric({
					app_name: appName,
					device_uniq_id: getUniqId(),
					country: user?.country,
					error: obfuscateUserDataFromError(
						e?.message ?? e?.name ?? 'unknown_error',
					),
					error_extra: isOauth ? 'oauth_error' : error_extra,
					server_payload: safeStringify(payload),
					gateway_method,
					...rest,
				}).serialize(),
			);
		},
		[appName, isOauth, user],
	);

	return {
		logAuthError,
	};
};

export function useOnInitError(
	payload: Omit<AuthVitalsLog, 'app_name' | 'device_uniq_id'>,
) {
	const {user} = useUserData();
	const {appName, isOauth} = useAppState();
	useEffect(() => {
		if (!user) return;
		logCenter.log(
			new AuthVitalsMetric({
				app_name: appName,
				device_uniq_id: getUniqId(),
				error_extra: isOauth ? 'oauth_error' : undefined,
				country: user.country,
				...payload,
			}).serialize(),
		);
	}, [payload, appName, isOauth, user]);
}
